var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0a09fd24156e69fcf00d054d58a84a386145df10"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import {isIgnorableError} from "./src/utils/sentry/ignoreErrors";

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://3e9551af2d59444ba560e92a9a7b4c09@o34888.ingest.sentry.io/6276071";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  enabled: !process.env.CI,

  // It seems like `ignoreErrors` is not working for us.
  // This is a workaround.
  // TODO: Why is `ignoreErrors` not working?
  beforeSend(event, hint) {
    const errorMsg = hint.originalException?.toString();
    if (errorMsg && isIgnorableError(errorMsg)) {
      return null;
    }
    return event;
  },
});
